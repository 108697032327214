var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.$route.query.code === 'event' ? _c('div', [_c('v-tabs', {
    staticClass: "v-tabs--primary tab--block",
    attrs: {
      "hide-slider": "",
      "height": "auto"
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', {
    on: {
      "click": function ($event) {
        return _vm.isEvent(true);
      }
    }
  }, [_vm._v("진행중인 이벤트")]), _c('v-tab', {
    on: {
      "click": function ($event) {
        return _vm.isEvent(false);
      }
    }
  }, [_vm._v("종료된 이벤트")])], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "mb-12 mb-lg-20"
  }, [_c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-select', {
    staticClass: "v-input--small w-100 w-md-100px",
    attrs: {
      "placeholder": "선택",
      "items": _vm.filterItems,
      "persistent-placeholder": "",
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "item-text": "text",
      "item-valuie": "value"
    },
    model: {
      value: _vm.filter.searchKey,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchKey", $$v);
      },
      expression: "filter.searchKey"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "9",
      "md": "auto"
    }
  }, [_c('v-text-field', {
    staticClass: "v-input--small w-100 w-md-240px",
    attrs: {
      "disabled": _vm.filter.searchKey == null,
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "placeholder": "검색어를 입력하세요."
    },
    model: {
      value: _vm.filter.searchValue,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchValue", $$v);
      },
      expression: "filter.searchValue"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3",
      "md": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "h-100 w-100 min-w-md-60px",
    attrs: {
      "color": "grey-6",
      "dense": ""
    },
    on: {
      "click": _vm.search
    }
  }, [_c('span', {
    staticClass: "white--text font-size-14"
  }, [_vm._v("검색")])])], 1)], 1)], 1), _vm.$route.query.code === 'volunteer' || _vm.$route.query.code === 'event' || _vm.$route.query.code === 'fair' || _vm.$route.query.code === 'newsletter' ? _c('div', [_c('v-row', {
    staticClass: "board-contents"
  }, _vm._l(_vm.boards, function (board, idx) {
    return _c('v-col', {
      key: idx,
      attrs: {
        "cols": "6",
        "md": "4"
      }
    }, [_c('v-card', {
      staticClass: "h-100",
      attrs: {
        "outlined": ""
      },
      on: {
        "click": function ($event) {
          return _vm.show(board);
        }
      }
    }, [_c('v-img', {
      staticClass: "w-100",
      attrs: {
        "src": board.thumb,
        "aspect-ratio": 1 / 1
      }
    }), _c('div', {
      staticClass: "pa-16 pa-md-24"
    }, [_c('h5', {
      staticClass: "font-size-16 font-size-lg-20 line-height-1 ellip--2"
    }, [_vm._v(" " + _vm._s(board.subject) + " ")]), _vm.$route.query.code === 'volunteer' || _vm.$route.query.code === 'event' ? [_c('v-divider', {
      staticClass: "my-8 my-lg-16"
    }), _c('p', {
      staticClass: "page-text ellip--2"
    }, [_vm._v(" " + _vm._s(_vm.$route.query.code === 'event' ? `${board.startAt} - ${board.endAt}` : board.summary) + " ")])] : _vm._e()], 2)], 1)], 1);
  }), 1)], 1) : _c('table', {
    staticClass: "board-list"
  }, [_vm._m(0), _c('tbody', _vm._l(_vm.boards, function (board, idx) {
    return _c('tr', {
      key: idx,
      on: {
        "click": function ($event) {
          return _vm.show(board);
        }
      }
    }, [_c('td', {
      staticClass: "board-list__tit ellip",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(board.subject) + " ")]), _c('td', {
      staticClass: "board-list__txt board-list__txt--first",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(board.writer.name) + " ")]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(board.updatedAt || _vm.$dayjs(board.createdAt).format("YYYY-MM-DD")) + " ")]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(board.viewCount) + " ")])]);
  }), 0)]), _c('div', {
    staticClass: "v-pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount
    },
    on: {
      "input": function ($event) {
        return _vm.init();
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1), !!_vm.write ? _c('div', {
    staticClass: "mt-20 mt-lg-40"
  }, [_c('v-row', [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "outlined": "",
      "color": "grey"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push(`?code=${_vm.filter.code}&&mode=input`);
      }
    }
  }, [_vm._v(" 글쓰기 ")])], 1)], 1)], 1) : _vm._e()]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', {
    staticClass: "d-none d-lg-table-header"
  }, [_c('tr', [_c('th', {
    staticClass: "board-list__txt",
    staticStyle: {
      "width": "56.6%"
    }
  }, [_vm._v("제목 ")]), _c('th', {
    staticClass: "board-list__txt",
    staticStyle: {
      "width": "16.7%"
    }
  }, [_vm._v("작성자")]), _c('th', {
    staticClass: "board-list__txt",
    staticStyle: {
      "width": "16.7%"
    }
  }, [_vm._v("작성일")]), _c('th', {
    staticClass: "board-list__txt",
    staticStyle: {
      "width": "10%"
    }
  }, [_vm._v("조회")])])]);

}]

export { render, staticRenderFns }